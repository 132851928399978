import "../CSS/home.css"
const Starbackground = ()=>{
    return (
        <div className="w-full h-full flex ">
            <div className="contentanim flex justify-end z-10">
                <div className="circle rounded-full mr-24 h-1 w-1" id="lum1"></div>
                <div className="circle rounded-full mr-16 h-1 w-1" id="lum2"></div>
                <div className="circle rounded-full mr-12 h-1 w-1" id="lum3" ></div>
                <div className="circle rounded-full mr-16 h-1 w-1" id="lum4"></div>
                <div className="circle rounded-full mr-20 h-1 w-1" id="lum5"></div>
                <div className="circle rounded-full mr-24 h-1 w-1" id="lum6"></div>
                <div className="circle rounded-full mr-36 h-1 w-1" id="lum7"></div>
            </div>
        </div>
    )
}
export default Starbackground