import { Box } from "@react-three/drei";
import { TextureLoader } from 'three/src/loaders/TextureLoader';
import { useLoader } from '@react-three/fiber';

export const TkComponent = () => {
    const tkText = useLoader(TextureLoader, process.env.PUBLIC_URL + '/ExpSvg/textTk.png');
    const tkLogo = useLoader(TextureLoader, process.env.PUBLIC_URL + '/ExpSvg/tk.png');

    return (
        <group  position={[-1,0.5,-35]} rotation-y={-0.6} >
            <Box args={[0.5,0.5,0.5]} position={[1,0,0]}>
                <meshStandardMaterial attach='material' map={tkLogo}/>
            </Box>
            <Box position={[1,1,0]} args={[2,3,0]}>
                <meshStandardMaterial attach='material' map={tkText}/>
            </Box>
        </group>
    )
}