import { Canvas } from '@react-three/fiber';
import { ScrollControls } from "@react-three/drei";
import { TechnoComponent } from "../components/technoComponent";
import { Suspense} from 'react';
import { Overlay } from "./Overlay";
import { Background } from "../components/background";
import { TravelComponent } from "./travelComponent";
import { NeonDroit } from "../components/neonDroit";
import { NeonRing } from "../components/neonRing";
import Starbackground from "../components/starbackground";
import { ExpComponent } from '../components/expComponent';
import { AmexioComponent } from '../components/AmexioComponent';
import { TkComponent } from '../components/TKComponent';

const TechnoScreen=()=> {
        return (
            <div className="z-30">
                <div className="">
                    <Starbackground/>
                    <div id='canvas'>
                        <Suspense fallback={null}>
                            <Canvas shadows>
                                    <ScrollControls pages={20} damping={0.25}>
                                    <TravelComponent/>   
                                            <NeonRing pos={[0,-0.5,-10]}/><NeonRing pos={[0,-0.5,-20]}/><NeonRing pos={[0,-0.5,-30]}/><NeonRing pos={[0,-0.5,-40]}/><NeonRing pos={[0,-0.5,-50]}/>
                                            <TkComponent/>
                                            <Background/>
                                            <TechnoComponent/>
                                            <AmexioComponent/>
                                            <ExpComponent/>
                                    
                                        <Overlay/>
                                    </ScrollControls>
                            </Canvas>
                        </Suspense>
                    </div>       
                </div> 
            </div>
        )
   
}
export default TechnoScreen